module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-monetization/gatsby-browser.js'),
      options: {"plugins":[],"paymentPointer":"$ilp.uphold.com/BfyYUyGBrEgA"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/layout.jsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["en","es"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://67e863347a06470ca38859621a7f5830@sentry.io/2877175","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Cormorant Garamond","Della Respira"]}},
    }]
